<template>
  <div class="about">
    <heade></heade>
    <div class="box-all">
      <div class="select-box">
        <!---    @focus="handleFocus"
          @blur="handleBlur"-->
        <span>大赛标题：</span>
        <a-select
          show-search
          placeholder="请选择大赛标题"
          option-filter-prop="children"
          style="width: 550px; margin-right: 30px"
          :filter-option="filterOption"
          @change="handleChange"
        >
          <a-select-option value=""> 全部 </a-select-option>
          <a-select-option
            :value="item"
            v-for="(item, index) in projects"
            :key="index"
          >
            {{ item }}
          </a-select-option>
          <!-- <a-select-option value="lucy"> Lucy </a-select-option>
          <a-select-option value="tom"> Tom </a-select-option> -->
        </a-select>

        <span>大赛阶段：</span>
        <a-select
          show-search
          placeholder="请选择大赛阶段"
          option-filter-prop="children"
          style="width: 150px; margin-right: 30px"
          :filter-option="filterOption"
          @change="handleChangeJD"
        >
          <a-select-option value=""> 全部 </a-select-option>
          <a-select-option
            :value="item"
            v-for="(item, index) in stages"
            :key="index"
          >
            {{ item }}
          </a-select-option>
        </a-select>
        <span>状态检索：</span>
        <a-select
          v-model="statusV"
          show-search
          placeholder="请选择状态"
          option-filter-prop="children"
          style="width: 150px; margin-right: 30px"
          :filter-option="filterOption"
          @change="handleChangestutas"
        >
          <a-select-option
            :value="item"
            v-for="(item, index) in stutasValue"
            :key="index"
          >
            {{ item }}
          </a-select-option>
          <!-- <a-select-option value="lucy"> Lucy </a-select-option>
          <a-select-option value="tom"> Tom </a-select-option> -->
        </a-select>
        <div style="margin-top: 5px">
          <span style="font-weight: bold; margin: 3px 10px 0 0"
            >已评审/总数：<span style="color: red">{{ yi }}</span> /
            <span style="color: rgb(59, 183, 5)">{{ dai }}</span></span
          >
          <a-button type="link" @click="goTo" style="font-weight: bold">
            【 评审工作说明 】
          </a-button>
          <a-button
            type="danger"
            @click="PhbFun"
            style="float: right; font-weight: bold; margin-top: 3px"
            size="small"
          >
            排行榜
          </a-button>
          <a-button
            type="primary"
            plain
            @click="stFun"
            style="
              float: right;
              font-weight: bold;
              margin-top: 3px;
              margin-right: 10px;
            "
            size="small"
          >
            赛题
          </a-button>
        </div>

        <a-modal
          v-model="visiblePhb"
          title="排行榜"
          @ok="PhbFunOk"
          width="92%"
          bordered
          :footer="null"
        >
          <span>大赛标题：</span>
          <a-select
            v-model="projectPhb"
            show-search
            :autoFocus="true"
            placeholder="请选择大赛标题"
            option-filter-prop="children"
            style="width: 550px; margin-right: 30px"
            :filter-option="filterOption"
            @change="handleChangePhb"
          >
            <a-select-option
              :value="item"
              v-for="(item, index) in projects"
              :key="index"
            >
              {{ item }}
            </a-select-option>
            <!-- <a-select-option value="lucy"> Lucy </a-select-option>
          <a-select-option value="tom"> Tom </a-select-option> -->
          </a-select>

          <span>大赛阶段：</span>
          <a-select
            v-model="stagePhb"
            show-search
            placeholder="请选择大赛阶段"
            option-filter-prop="children"
            style="width: 200px"
            :filter-option="filterOption"
            @change="handleChangeJDPhb"
          >
            <a-select-option
              :value="item"
              v-for="(item, index) in stages"
              :key="index"
            >
              {{ item }}
            </a-select-option>
          </a-select>
          <a-button
            type="primary"
            style="margin-left: 20px"
            size="small"
            @click="clickSFun"
          >
            查询
          </a-button>
          <a-table
            style="margin-top: 15px"
            :pagination="false"
            :columns="columnsPhb"
            :data-source="dataPhb"
            :scroll="{ x: 1500, y: 700 }"
          >
            <!-- <a slot="action" slot-scope="text">action</a> -->
          </a-table>
        </a-modal>

        <a-modal
          v-model="visibleSt"
          title="赛题"
          @ok="stFunOk"
          width="98%"
          bordered
          :footer="null"
        >
          <span>大赛标题：</span>
          <a-select
            v-model="projectST"
            show-search
            placeholder="请选择大赛标题"
            option-filter-prop="children"
            style="width: 550px; margin-right: 30px"
            :filter-option="filterOption"
            @change="handleChangeST"
          >
            <a-select-option
              :value="item"
              v-for="(item, index) in projects"
              :key="index"
            >
              {{ item }}
            </a-select-option>
            <!-- <a-select-option value="lucy"> Lucy </a-select-option>
          <a-select-option value="tom"> Tom </a-select-option> -->
          </a-select>
          <span>赛题编号：</span>
          <a-input
            placeholder="赛题编号检索"
            style="width: 200px"
            v-model="stValue"
          />

          <a-button
            type="primary"
            style="margin-left: 20px"
            size="small"
            @click="clickSTFun"
          >
            查询
          </a-button>
          <a-table
            style="margin-top: 15px"
            :pagination="false"
            :columns="columnsST"
            :data-source="dataST"
            :scroll="{ x: 1500, y: 600 }"
            bordered
          >
            <p
              slot="caz"
              slot-scope="text, record"
              @click="detailFun(text, record)"
              style="color: #1890ff; cursor: pointer"
            >
              详情
            </p>
            <!-- <VueMarkdown
              slot="info"
              slot-scope="text"
              :source="text"
              class="articalContent markdown-body"
            ></VueMarkdown>
            <VueMarkdown
              slot="topicreq"
              slot-scope="text"
              :source="text"
              class="articalContent markdown-body"
            ></VueMarkdown>
            <VueMarkdown
              slot="target"
              slot-scope="text"
              :source="text"
              class="articalContent markdown-body"
            ></VueMarkdown>
            <VueMarkdown
              slot="comment"
              slot-scope="text"
              :source="text"
              class="articalContent markdown-body"
            ></VueMarkdown>
            <VueMarkdown
              slot="feature"
              slot-scope="text"
              :source="text"
              class="articalContent markdown-body"
            ></VueMarkdown>
            <VueMarkdown
              slot="license"
              slot-scope="text"
              :source="text"
              class="articalContent markdown-body"
            ></VueMarkdown>
            <VueMarkdown
              slot="refdata"
              slot-scope="text"
              :source="text"
              class="articalContent markdown-body"
            ></VueMarkdown> -->
          </a-table>
        </a-modal>
      </div>
      <!-- <a-table
        :columns="columns"
        :data-source="dataList"
        :loading="loading"
        :pagination="false"
      
        bordered
        @change="handleTableChange"
      >
        <span
          slot="ps"
          slot-scope="text"
          :style="text == '待评审' ? 'color: #3bb705;font-weight: bold;' : ''"
        >
          {{ text }}
        </span>
        <a slot="urlAction" slot-scope="text" :href="text" target="_blank">
         查看
        </a>
        <a
          slot="pid"
          slot-scope="text,record"
          href="javascript:;"
          @click="pidFun(text, record)"
          style="color: #1890ff"
        >
          {{ text }}
        </a>
        <a slot="action" slot-scope="text" @click="editFun(text._id,text.tpname,text.school,text.teamid,text.teamname)">评审</a>
      </a-table> -->
      <el-table border :data="dataList" style="width: 100%" height="600">
        <el-table-column fixed prop="project" label="大赛标题">
        </el-table-column>
        <el-table-column prop="stage" label="大赛阶段" width="100">
        </el-table-column>
        <el-table-column prop="teamid" label="队伍编号" width="150">
        </el-table-column>
        <el-table-column prop="teamname" label="队伍名称" width="150">
        </el-table-column>
        <el-table-column prop="total" label="已评分数" width="100">
        </el-table-column>
        <el-table-column label="状态" width="100">
          <template slot-scope="scope">
            <span
              :style="
                scope.row.state == '待评审'
                  ? 'color: #3bb705;font-weight: bold;'
                  : ''
              "
            >
              {{ scope.row.state }}
            </span>
          </template>
        </el-table-column>
        <el-table-column label="作品地址" width="100">
          <template slot-scope="scope">
            <a :href="scope.row.url" target="_blank">查看</a>
          </template>
        </el-table-column>
        <el-table-column prop="pid" label="项目编号" width="80">
          <template slot-scope="scope">
            <span
              style="cursor: pointer"
              @click="pidFun(scope.row.pid, scope.row)"
              target="_blank"
              >{{ scope.row.pid }}</span
            >
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="80">
          <template slot-scope="scope">
            <el-button
              @click="
                editFun(
                  scope.row._id,
                  scope.row.tpname,
                  scope.row.school,
                  scope.row.teamid,
                  scope.row.teamname
                )
              "
              type="text"
              size="small"
              >评审</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <a-pagination
        v-model="current"
        :total="totalT"
        show-less-items
        @change="pageFun"
        style="text-align: right; margin-top: 10px"
        :hideOnSinglePage="true"
      />
    </div>
    <a-modal
      :title="`评审${edit ? '（已评审后，只可查看）' : ''}`"
      :visible="visible"
      @cancel="cancelZc"
      :maskClosable="false"
      width="90%"
    >
      <template slot="footer">
        <a-button key="back" @click="chalFun"> 取消 </a-button>
        <a-button type="danger" ghost @click="handleOk" :disabled="edit">
          暂存
        </a-button>
        <a-button
          key="submit"
          type="primary"
          @click="handleOkSub"
          :disabled="edit"
        >
          提交
        </a-button>
      </template>
      <div class="pp">
        <a-descriptions
          title=""
          bordered
          :column="{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }"
        >
          <a-descriptions-item label="队伍ID">
            {{ teamid }}
          </a-descriptions-item>
          <a-descriptions-item label="队伍名称">
            {{ teamname }}
          </a-descriptions-item>
          <a-descriptions-item label="学校名称">
            {{ school }}
          </a-descriptions-item>
          <a-descriptions-item label="赛题名称">
            {{ tpname || "暂无" }}
          </a-descriptions-item>
        </a-descriptions>
        <a-divider />
        <p style="width: 100%; font-weight: bold" v-if="fields_grade">
          <span>总计（{{ totalJS }}分）</span>
        </p>

        <!-- <p v-for="(item, index) in fields" :key="index" class="add">
          <span style="min-width: 120px; display: inline-block"
            >{{ item.title }}（{{ Number(item.score) }}分）</span
          >

          <a-input-number
            :min="0"
            :max="parseInt(item.score)"
            @blur="onChangeIndex($event,index,item.score)"
            :default-value="item.value"
            v-model="item.value"
    
            style="width: 100px"
            :disabled="edit"
          />
          <span style=" display:block;   display: block;
    color: #ffab00;
    margin-top: 10px;">说明：{{item.desc || '暂无'}}</span>
        </p> -->

        <a-table
          :columns="columnsBt"
          :data-source="fields"
          :scroll="{ x: 1300 }"
          :pagination="false"
          bordered
        >
          <span slot="tindexH" slot-scope="tindex" v-html="tindex"></span>
          <span slot="descH" slot-scope="desc" v-html="desc"></span>
          <span slot="scoreH" slot-scope="score, record, index">
            <!-- <span>{{ record.value }}</span> -->
            <span v-if="!record.grade">
              <a-input-number
                :disabled="edit"
                :min="0"
                :max="parseInt(score)"
                @blur="onChangeIndex($event, index, score)"
                v-model="record.value"
                style="width: 100px"
              />
              <span>（{{ record.score }}分）</span>
            </span>
            <span v-else>暂无</span>
          </span>
          <span slot="gradeH" slot-scope="score, record, index">
            <!-- <span>{{ record.value }}</span> -->
            <span v-if="record.grade && record.grade != ''">
              <a-select
                :default-value="showValue[index]"
                :key="showValue[index]"
                style="width: 120px"
                 :disabled="edit"
                @change="handleChangegrade($event, index)"
              >
                <a-select-option
                 
                  :value="`{\&quot;name\&quot;:\&quot;${item.name}\&quot;,\&quot;score\&quot;:${item.score}}`"
                  v-for="(item, index) in JSON.parse(record.grade)"
                  :key="index"
                >
                  {{ item.name }} {{ item.score }}(分)</a-select-option
                >
              </a-select>
         
            </span>
            <span v-else>暂无</span>
          </span>
        </a-table>

        <p style="width: 100%; font-weight: bold" class="add">
          <span style="min-width: 120px; display: inline-block"
            >总分（分）</span
          >
          <a-input
            size="small"
            style="width: 100px"
            disabled
            v-model="totalZJ"
          />
        </p>
        <a-divider dashed>评语</a-divider>
        <p style="width: 100%">
          <a-textarea
            placeholder="输入评语"
            :rows="4"
            v-model="comment"
            :disabled="edit"
          />
        </p>
      </div>
    </a-modal>
    <a-modal
      zIndex="1001"
      v-model="projectId"
      title="赛题"
      @ok="stFunOk"
      width="98%"
      bordered
      :footer="null"
    >
      <a-descriptions title="" layout="vertical" bordered>
        <a-descriptions-item label="编号">
          {{ dataPro.code }}
        </a-descriptions-item>

        <a-descriptions-item label="标题">
          {{ dataPro.title }}
        </a-descriptions-item>
        <a-descriptions-item label="难度">
          {{ dataPro.level }}
        </a-descriptions-item>
        <a-descriptions-item label="简介" :span="3">
          <VueMarkdown
            :source="dataPro.info"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </a-descriptions-item>
        <a-descriptions-item label="参赛要求" :span="3">
          <VueMarkdown
            :source="dataPro.require"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </a-descriptions-item>

        <a-descriptions-item label="特征" :span="3">
          <VueMarkdown
            :source="dataPro.feature"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </a-descriptions-item>
        <a-descriptions-item label="预期目标" :span="3">
          <VueMarkdown
            :source="dataPro.target"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </a-descriptions-item>
        <a-descriptions-item label="License" :span="3">
          <VueMarkdown
            :source="dataPro.license"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </a-descriptions-item>
        <a-descriptions-item label="参考资料" :span="3">
          <VueMarkdown
            :source="dataPro.refdata"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </a-descriptions-item>
        <a-descriptions-item label="备注" :span="3">
          <VueMarkdown
            :source="dataPro.comment"
            class="articalContent markdown-body"
          ></VueMarkdown>
        </a-descriptions-item>
      </a-descriptions>
    </a-modal>
  </div>
</template>
<script>
import heade from "../components/heade.vue";
import VueMarkdown from "vue-markdown";

const columns = [
  {
    title: "大赛标题",

    dataIndex: "project",
    key: "project",
  },
  { title: "大赛阶段", dataIndex: "stage", key: "stage", width: 100 },
  { title: "队伍编号", dataIndex: "teamid", key: "teamid", width: 150 },

  { title: "队伍名称", dataIndex: "teamname", key: "teamname", width: 200 },
  {
    title: "已评分数",
    dataIndex: "total",
    key: "total",
    width: 120,
    sorter: true,
  },

  {
    title: "状态",
    dataIndex: "state",
    key: "state",
    sorter: true,
    width: 100,
    scopedSlots: { customRender: "ps" },
  },
  {
    title: "作品地址",
    width: 100,
    dataIndex: "url",
    key: "url",
    scopedSlots: { customRender: "urlAction" },
  },
  {
    title: "项目编号",
    dataIndex: "pid",
    key: "pid",
    width: 100,

    scopedSlots: { customRender: "pid" },
  },
  // { title: "Column 3", dataIndex: "address", key: "3", width: 150 },
  // { title: "Column 4", dataIndex: "address", key: "4", width: 150 },
  // { title: "Column 5", dataIndex: "address", key: "5", width: 150 },
  // { title: "Column 6", dataIndex: "address", key: "6", width: 150 },
  // { title: "Column 7", dataIndex: "address", key: "7", width: 150 },
  // { title: "Column 8", dataIndex: "address", key: "8" },
  {
    title: "操作",
    key: "operation",
    fixed: "right",
    width: 80,
    scopedSlots: { customRender: "action" },
  },
];

// const data = [];
// for (let i = 0; i < 100; i++) {
//   data.push({
//     key: i,
//     name: `Edrward ${i}`,
//     age: 32,
//     address: `London Park no. ${i}`,
//   });
// }
export default {
  data() {
    return {
      fields_grade: true,
      stutasValue: ["待评审", "已评审", "全部"],
      totalZJ: 0,
      currentValue: 0,
      columnsBt: [
        {
          title: "说明",

          dataIndex: "title",
          key: "title",
        },

        {
          title: "评分要点",

          dataIndex: "desc",
          key: "desc",
          scopedSlots: { customRender: "descH" },
        },
        {
          title: "等级",
          width: 200,
          dataIndex: "grade",
          key: "grade",
          scopedSlots: { customRender: "gradeH" },
        },
        {
          title: "分值",
          width: 200,
          dataIndex: "score",
          key: "score",
          scopedSlots: { customRender: "scoreH" },
          fixed: "right",
        },
      ],
      showValue: [],
      fields: [],
      projectId: false,
      statusV: "全部",
      teamname: "",
      teamid: "",
      yi: "",
      dai: "",
      stagePhb: undefined,
      projectPhb: undefined,
      projectST: undefined,
      stValue: "",
      visiblePhb: false,
      visibleSt: false,
      edit: false,
      projects: [],
      stages: [],
      loading: false,
      totalJS: 0,
      fields: [],
      comment: "",
      visible: false,
      dataList: [],
      columns,
      project: "",
      stage: "",
      pagination: {},
      page: 1,
      tatalZJ: "",
      currentValue: 0,
      current: 1,
      totalT: 0,
      totalZJ: 0,
      id: "",
      smUrl: "",
      tpname: "",
      school: "",
      columnsPhb: [
        {
          title: "序号",

          dataIndex: "key",
          key: "key",
          width: 100,
          customRender: (text, r, index) => `${index + 1}`,
          fixed: "left",
        },
        {
          title: "队伍ID",

          dataIndex: "teamid",
          key: "teamid",
          width: 200,
          fixed: "left",
        },
        { title: "队伍名", dataIndex: "title", key: "title" },
        // { title: "学校名称", dataIndex: "school", key: "school" },
        // { title: "赛题名称", dataIndex: "tpname", key: "tpname" },

        { title: "提交次数", dataIndex: "commit_count", key: "commit_count" },
        { title: "最后提交时间", dataIndex: "commit_time", key: "commit_time" },
        {
          title: "总分",
          dataIndex: "test_score",
          key: "test_score",
        },
      ],
      columnsST: [
        {
          title: "序号",

          dataIndex: "key",
          key: "key",
          width: 100,
          customRender: (text, r, index) => `${index + 1}`,
          fixed: "left",
        },
        { title: "编号", dataIndex: "code", key: "code" },
        { title: "标题", dataIndex: "title", key: "title" },
        // {
        //   title: "简介",
        //   dataIndex: "info",
        //   key: "info",
        //   width: 500,
        //   scopedSlots: { customRender: "info" },
        // },
        // { title: "参赛要求", dataIndex: "require", key: "require" ,       width: 500,},

        // {
        //   title: "题目要求",
        //   dataIndex: "topicreq",
        //   key: "topicreq",
        //   width: 500,
        //   scopedSlots: { customRender: "topicreq" },
        // },
        // {
        //   title: "特征",
        //   dataIndex: "feature",
        //   key: "feature ",
        //   width: 500,
        //   scopedSlots: { customRender: "feature" },
        // },
        // {
        //   title: "预期目标",
        //   dataIndex: "target",
        //   key: "target",
        //   width: 500,
        //   scopedSlots: { customRender: "target" },
        // },
        // {
        //   title: "License",
        //   dataIndex: "license",
        //   key: "license ",
        //   width: 500,
        //   scopedSlots: { customRender: "license" },
        // },
        // {
        //   title: "参考资料",
        //   dataIndex: "refdata",
        //   key: "refdata  ",
        //   width: 500,
        //   scopedSlots: { customRender: "refdata" },
        // },
        // {
        //   title: "备注",
        //   dataIndex: "comment",
        //   key: "comment",
        //   width: 500,
        //   scopedSlots: { customRender: "comment" },
        // },
        {
          title: "难度",
          dataIndex: "level",
          key: "level",

          // fixed: "right",
        },
        {
          title: "操作",
          dataIndex: "commentcomment",
          key: "commentcomment",
          width: 100,
          scopedSlots: { customRender: "caz" },
          fixed: "right",
        },
      ],

      dataPhb: [
        // {
        //   key: 0,
        //   name: `Edrward `,
        //   age: 32,
        //   address: `London Park no`,
        // },
        // {
        //   key: 1,
        //   name: `Edrward`,
        //   age: 32,
        //   address: `London Park no. `,
        // },
      ],
      dataST: [],
      dataPro: {},
      sort_k: "",
      sort_v: "",
      gradeData: [],
    };
  },
  components: {
    heade,
    VueMarkdown,
  },
  mounted() {
    this.initDS();
    this.init();
    this.initSmUrl();
  },
  methods: {
    chalFun(){
      this.visible = false;
      this.showValue = [];
    },
    PhbFunOk(){},
    stFunOk(){},
    onChangeIndex(e, index, max) {
      console.log(e);
      this.currentValue = e.target._value;
      if (parseInt(this.currentValue) > parseInt(max)) {
        this.currentValue = max;
      }
      // this.currentValue =
      debugger;
      this.totalZJ = 0;
      console.log(index);
      console.log("this.fieldsthis.fields", this.fields);

      this.fields[index].value = Number(this.currentValue);
      console.log(this.fields);

      for (let i = 0; i < this.fields.length; i++) {
        this.totalZJ += Number(this.fields[i].value);
      }
      // if( this.totalZJ === 0){
      //   this.totalZJ = ''
      // }
    },

    detailFun(name, obj) {
      this.dataPro = obj;
      this.projectId = true;
      console.log(this.dataPro);
    },
    cancelZc() {
      this.visible = false;
      console.log("this.editthis.edit", this.edit);

      if (!this.edit) {
        this.handleOk();
      }
    },
    pidFun(id, obj) {
      console.log(obj);
      this.projectId = true;
      //columnsPro dataPro
      let data = {
        op: "gettopic",
        code: id,
        project: obj.project,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/_judge/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          if (response.data.value == "ok") {
            _this.dataPro = response.data.data;
          } else if (response.data.type == "sessionerror") {
            _this.$message.info(response.data.msg);
            _this.$router.push({ path: "./" });
          }

          // }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    initDS() {
      let data = {
        op: "projectlist",
        // _id:id
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/_judge/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          if (response.data.value == "ok") {
            (_this.projects = response.data.projects),
              (_this.stages = response.data.stages);
          } else if (response.data.type == "sessionerror") {
            _this.$message.info(response.data.msg);
            _this.$router.push({ path: "./" });
          }

          // }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    initSmUrl() {
      let data = {
        op: "geturl",
        // _id:id
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/_judge/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          if (response.data.value == "ok") {
            _this.smUrl = response.data.data;
          }

          // }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    stFun() {
      this.visibleSt = true;
      this.projectST = undefined;
      this.stValue = "";
      this.dataST = [];
      // this.stageST = undefined;
    },

    // onChange(value) {
    //   console.log('value',value);
    //   this.currentValue = value;
    // },
    PhbFun() {
      this.visiblePhb = true;
      this.projectPhb = undefined;
      this.stagePhb = undefined;
    },
    goTo() {
      console.log(this.smUrl);
      window.open(this.smUrl);
    },
    // onChangeIndex(e,index,max) {

    //   this.currentValue = e.target._value;
    //    if(Number(this.currentValue) >Number(max)){
    //      this.currentValue =Number(max)
    //    }

    //   console.log(index,  this.fields)
    //   debugger;
    //   this.totalZJ = 0;
    //   this.fields[index].value = Number(this.currentValue);
    //   console.log('this.fields[index].value',this.fields[index].value)
    //   for (let i = 0; i < this.fields.length; i++) {
    //     this.totalZJ += Number(this.fields[i].value);
    //   }
    //   // if( this.totalZJ === 0){
    //   //   this.totalZJ = ''
    //   // }
    // },
    onSearch() {
      console.log(this.projectST);
      let data = {
        op: "topiclist",
        code: this.stValue,
        project: this.projectST,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/_judge/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          if (response.data.value == "ok") {
            _this.dataST = response.data.data;
            // _this.$message.success("暂存成功", 1);
            // _this.init();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleTableChange(pagination, filters, sorter) {
      console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      console.log("sorter", sorter);
      this.sort_k = sorter.field;
      this.sort_v = sorter.order == "ascend" ? 1 : -1;
      // this.fetch({
      //   results: pagination.pageSize,
      //   page: pagination.current,
      //   sortField: sorter.field,
      //   sortOrder: sorter.order,
      //   ...filters,
      // });
      this.pagre = pagination.current;
      this.init();
    },
    // fetch(params = {}) {
    //   this.loading = true;
    //   queryData({
    //     results: 10,
    //     ...params,
    //   }).then(({ data }) => {
    //     const pagination = { ...this.pagination };
    //     // Read total count from server
    //     // pagination.total = data.totalCount;
    //     pagination.total = 200;
    //     this.loading = false;
    //     this.data = data.results;
    //     this.pagination = pagination;
    //   });
    // },

    handleOk() {
      let data = {
        op: "review",
        _id: this.id,
        fields: this.fields,
        total: this.totalZJ,
        gradeValue: this.gradeData,
        comment: this.comment,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/_judge/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          if (response.data.value == "ok") {
            _this.visible = false;
            _this.$message.success("暂存成功", 1);
            _this.showValue = [];
            _this.init();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleOkSub() {
      let data = {
        op: "submitreview",
        _id: this.id,
        fields: this.fields,
        total: this.totalZJ,
        gradeValue: this.gradeData,
        comment: this.comment,
        // school:this.school,
        // tpname:this.tpname
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/_judge/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          if (response.data.value == "ok") {
            _this.visible = false;
            _this.$message.success("提交成功", 1);
            _this.init();
            _this.showValue = [];
          }
          else {
            _this.$message.info(response.data.msg)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
     // 在某些情况下需要强制重新渲染组件
     forceRerender() {
        // 调用 $forceUpdate()方法
        this.$forceUpdate();
    },
    editFun(id, tpname, school, teamid, teamname) {
      console.log(tpname, school);
      this.id = id;
      this.tpname = tpname || "";
      this.school = school;
      this.teamid = teamid;
      this.teamname = teamname;

      let data = {
        op: "getjudgetemp",
        _id: id,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/_judge/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.visible = true;
            _this.fields = response.data.data.fields;

            if (_this.fields[0].grade) {
              //判断等级字段是否存在，如果存在
              _this.fields_grade = false; //等级相关显示，否则
              _this.totalZJ = 0; //总数是0
              _this.showValue = []; // 用户回显的数据数组
              _this.gradeData=[]
              if (// 有等级的回显数据
                response.data.data.gradeValue &&
                response.data.data.gradeValue.length > 0
              ) {
                // 回显有值
                for(let i=0;i<response.data.data.gradeValue.length;i++){
                  _this.showValue.push(
                  `${response.data.data.gradeValue[i].name} ${
                    response.data.data.gradeValue[i].score
                  }(分)`
                );
             
                _this.$set(_this.showValue,i, `${response.data.data.gradeValue[i].name} ${
                    response.data.data.gradeValue[i].score
                  }(分)`)
                _this.gradeData.push({"name":response.data.data.gradeValue[i].name,"score":response.data.data.gradeValue[i].score})
                _this.totalZJ += Number(response.data.data.gradeValue[i].score);
                }
                console.log('走你')
                console.log(' _this.showValue', _this.showValue)

               
            
              } else {
                for(let i=0;i<_this.fields.length;i++){// 看看一共有几组数据，给每一个等级都默认选中第一个

                  _this.showValue.push(
                  `${(JSON.parse(_this.fields[i].grade))[0].name} ${
                    (JSON.parse(_this.fields[i].grade))[0].score
                  }(分)`
                  
                );
                // _this.$set(_this.showValue,i,`${(JSON.parse(_this.fields[i].grade))[0].name} ${
                //     (JSON.parse(_this.fields[i].grade))[0].score
                //   }(分)`)
           
             
                console.log('_this.gradeData',_this.showValue)
                _this.gradeData.push({"name":JSON.parse(_this.fields[i].grade)[0].name,"score":JSON.parse(_this.fields[i].grade)[0].score})
                console.log('_this.gradeData',_this.gradeData)
                _this.totalZJ += Number(JSON.parse(_this.fields[i].grade)[0].score);
                }
            
              }
            
        
              // for (let i = 0; i < _this.showValue.length; i++) {
              
            
            } else {
              _this.fields_grade = true;
              _this.totalZJ =
                response.data.data.total == "" ? 0 : response.data.data.total;
            }

            console.log(_this.fields);
            _this.comment = response.data.data.comment;

            _this.totalJS = 0;

            response.data.data.state == "已评审"
              ? (_this.edit = true)
              : (_this.edit = false);

            for (let i = 0; i < _this.fields.length; i++) {
              _this.totalJS += parseInt(_this.fields[i].score);
            }
          } else {
            _this.$message.info(response.data.msg, 3);
          }
          // }
        })
        .catch(function (error) {
          console.log(error);
        });

      console.log(id);
    },
    handleChange(value) {
      console.log(`selected ${value}`);
      this.project = value;
      this.current = 1;
      this.init();
    },
    handleChangegrade($event, index) {
      console.log($event, index);
      console.log(JSON.parse($event).name);
      if (this.gradeData.length != 0 && this.gradeData[index]) {
        this.gradeData[index].name = JSON.parse($event).name;
        this.gradeData[index].score = JSON.parse($event).score;
      } else {
        this.gradeData.push({
          name: JSON.parse($event).name,
          score: JSON.parse($event).score,
        });
      }
      this.totalZJ=0
      for(let i=0;i<this.gradeData.length;i++){
        this.totalZJ+=Number(this.gradeData[i].score)
        console.log(' this.totalJS this.totalJS',)
      }
      console.log("this.gradeData", this.gradeData);
    },
    handleChangestutas(value) {
      console.log(`selected ${value}`);
      this.statusV = value;
      this.current = 1;
      this.init();
    },

    handleChangeJD(value) {
      console.log(`selected ${value}`);
      this.stage = value;
      this.current = 1;
      this.init();
    },
    handleChangePhb(value) {
      console.log(`selected ${value}`);
      this.projectPhb = value;
    },
    handleChangeST(value) {
      console.log(`selected ${value}`);
      this.projectST = value;
      this.onSearch();
      // this.init()
    },
    handleChangeJDPhb(value) {
      console.log(`selected ${value}`);
      this.stagePhb = value;
    },
    handleBlur() {
      console.log("blur");
    },
    handleFocus() {
      console.log("focus");
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    clickSTFun() {
      if (this.projectST == undefined) {
        this.$message.info("请选择大赛标题！");
      } else {
        this.onSearch();
      }
    },
    clickSFun() {
      if (this.projectPhb == undefined) {
        this.$message.info("请选择大赛标题！");
      } else if (this.stagePhb == undefined) {
        this.$message.info("请选择大赛阶段！");
      } else {
        let data = {
          op: "ranklist",
          project: this.projectPhb,
          stage: this.stagePhb,
          // _id:id
        };
        let _this = this;
        this.$ajax
          .post(this.url + "/_judge/index.do", _this.Qs.stringify(data))
          .then(function (response) {
            if (response.data.value == "ok") {
              _this.dataPhb = response.data.data;
              if (_this.dataPhb[0]["f_score"]) {
                _this.columnsPhb.push({
                  title: "功能分",
                  dataIndex: "f_score",
                  key: "f_score",
                });
              } else {
                for (let i = 0; i < _this.columnsPhb.length; i++) {
                  if (_this.columnsPhb[i].title == "功能分") {
                    _this.columnsPhb.splice(i, 1);
                  }
                }
              }
              if (_this.dataPhb[0]["p_score"]) {
                _this.columnsPhb.push({
                  title: "性能分",
                  dataIndex: "p_score",
                  key: "p_score",
                });
              } else {
                for (let i = 0; i < _this.columnsPhb.length; i++) {
                  if (_this.columnsPhb[i].title == "性能分") {
                    _this.columnsPhb.splice(i, 1);
                  }
                }
              }

              // }
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    init() {
      let data = {
        op: "indexlist",

        page: this.current,
        project: this.project,
        stage: this.stage,
        sort_k: this.sort_k,
        sort_v: this.sort_v,
        state: this.statusV,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/_judge/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.dataList = response.data.data;
            _this.totalT = response.data.recordcount;
            _this.yi = response.data.f_count;
            _this.dai =response.data.all_count
            console.log(_this.dataList);
          } else if (response.data.value == "sessionerror") {
            _this.$message.info(response.data.msg);
            _this.$router.push({ path: "./" });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    pageFun(page, pageSize) {
      this.current = page;
      this.init();
    },
  },
};
</script>
<style scoped>
.box-all {
  padding: 10px 5%;
}
.select-box {
  text-align: left;
  padding-top: 10px;
}
.pp p {
  display: inline-block;
  width: 47%;
  margin-right: 3%;
}
.pp .add {
  color: #4bb0e0;
  margin-top: 20px;
}

/* /deep/.ant-modal-footer {
  display: none;
} */
/deep/ .ant-table-fixed-header .ant-table-scroll .ant-table-header {
  padding-bottom: 5px;
}

/deep/ .ant-table-fixed-left table,
/deep/ .ant-table-fixed-right table {
  width: min-content;
}
</style>
<style>
.tableHiddle {
  display: none;
}
.tableShow {
  display: block;
}
.articalContent img {
  width: 100%;
}
.ant-table-small > .ant-table-content .ant-table-header {
  overflow-y: overlay !important;
}

.ant-table-fixed-header
  > .ant-table-content
  > .ant-table-scroll
  > .ant-table-body {
  overflow-y: overlay !important;
}
</style>