<template>
  <div class="heade">
    <div><img src="../assets/logo.png" width="150px" /></div>
    <div>
      <span style="margin-right: 15px;font-weight: bold;">您好！{{ nameUser }}</span>
      <a-popconfirm
    title="确定退出登录？"
    ok-text="确定"
    cancel-text="取消"
    @confirm="confirm"
    @cancel="cancel"
  >
    <!-- <a href="#">Delete</a> -->
    <a-button type="primary" > 登出 </a-button>
  </a-popconfirm>
</div>
      
    
  </div>
</template>

<script>
export default {
  name: "heade",
  data(){
    return {
      nameUser:''
    }
  },
  mounted(){
  
    this.nameUser = window.localStorage.getItem("anjing-nameNew")

  },
  methods: {
  
    cancel(e) {
      console.log(e);
      // this.$message.error('Click on No');
    },
    confirm() {
      let data = {
        op: "logout",
        // phone: this.form.phone,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/_judge/login.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$message.success("登出成功", 3);
            window.localStorage.setItem(
                  "anjing-tokenName",
                 ''
                );
                window.localStorage.setItem("anjing-nameNew",'');
                window.localStorage.setItem(
                  "anjing-regPass",
                 ''
                );
            _this.$router.push({ path: "./" });

          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.heade {
  display: flex;
  justify-content: space-between;
  height: 60px;
  border-bottom: 1px solid #ccc;
  padding: 15px 5%;
}
</style>
